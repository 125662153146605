import React from "react";
import Layout from "~/components/layout";
import Seo from "~/components/seo";

function NotFoundPage() {
  return (
    <Layout>
      <Seo title="404: Not found" />
      <h1>404: Not Found</h1>
      <p>The page you are looking for does not exist.</p>
    </Layout>
  );
}

export default NotFoundPage;
